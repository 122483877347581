<template>
    <div style="width: 100%">
        <v-list dense style="width: 100%; min-width: 300px">
            <v-subheader style="font-size: 14px">
                <v-icon class="mr-2" color="primary" small>insights</v-icon>
                Actions</v-subheader>
            <v-divider></v-divider>
            <v-list-item @click="addTeam()" style="height: 30px">
                <v-list-item-action class="mx-0 px-0">
                    <v-icon small color="grey">add</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title> Create Team </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-subheader style="font-size: 14px">
                <v-icon class="mr-2" color="primary" small>insights</v-icon>
                Manage Teams</v-subheader>
            <v-divider></v-divider>
            <v-list-item v-for="(team, index) in teams" :key="index" @click="editTeam(team)">
                <v-icon small :color="team.colour" style="padding-right: 4px">fiber_manual_record</v-icon>
                {{ team.name }}
            </v-list-item>
        </v-list>

        <v-dialog v-model="dialog" :width="item.modalType === 'Add' ? '500px' : '1500px'" persistent
            :fullscreen="$vuetify.breakpoint.mobile">
            <v-card flat>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        <span v-if="item.modalType === 'Edit'">Manage Team</span>
                        <span v-else>Add Team</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" icon @click="saveTeam()" :loading="savingTeam" :disabled="!item.name">
                        <v-icon>save</v-icon>
                    </v-btn>
                    <v-btn color="red" icon @click="removeItem(item)" :loading="deletingTeam" v-if="item.id">
                        <v-icon>delete</v-icon>
                    </v-btn>
                    <v-btn icon @click="closeDialog()"><v-icon>close</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" :sm="this.item.modalType === 'Edit' ? 4 : 12">
                            <v-subheader style="font-size: 16px"> <v-icon color="secondary" class="mr-2"></v-icon>
                                Details
                            </v-subheader>
                            <v-divider></v-divider>
                            <v-text-field label="Name" v-model="item.name" outlined dense clearable></v-text-field>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Colour
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <el-color-picker v-model="item.colour" id="team-color"></el-color-picker>
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                        <!-- <v-col cols="12" sm="3" v-if="this.item.modalType === 'Edit'">
                            <v-subheader style="font-size: 16px"> <v-icon color="secondary" class="mr-2"></v-icon>
                                Team Roles
                            </v-subheader>
                            <v-divider></v-divider>
                            <v-list>
                                <v-list-item v-for="(role, index) in roles" :key="index">
                                    {{ role.name }}
                                </v-list-item>
                            </v-list>
                        </v-col> -->
                        <v-col cols="12" sm="4" v-if="this.item.modalType === 'Edit'">
                            <v-subheader style="font-size: 16px"> <v-icon color="secondary" class="mr-2"></v-icon>
                                Team Members
                            </v-subheader>
                            <v-divider></v-divider>
                            <v-text-field placeholder="Search" prepend-inner-icon="search" v-model="searchUsers"
                                outlined dense clearable> </v-text-field>
                            <v-list dense subheader style="max-height: 60vh; overflow-y: auto">
                                <v-list-item v-if="(item.userTeams && item.userTeams.length == 0)">
                                    <v-list-item-content>
                                        <v-list-item-title style="color: grey">
                                            No linked users
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-for="(user, index) in filteredUsers" :key="index">
                                    <v-list-item-action>
                                        <v-avatar :color="user.organisationUser.user.avatar ? 'white' : 'secondary'"
                                            size="36">
                                            <v-img v-if="user.organisationUser.user.avatar" referrerpolicy="no-referrer"
                                                :src="user.organisationUser.user.avatar" contain></v-img>
                                            <h2 v-else style="color: white; font-weight: normal">{{
                                                user.organisationUser.user.firstname.charAt(0) }}</h2>
                                        </v-avatar>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ user.organisationUser.user.firstname }}
                                            {{ user.organisationUser.user.surname }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ user.organisationUser.user.emailAddress }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action :key="loadingUsersKey">
                                        <v-btn color="red" :loading="loadingUsers.includes(index)" icon
                                            @click="removeUserItem(user, index)"><v-icon>block</v-icon></v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col cols="12" sm="4" :key="loadingUsersKey" v-if="this.item.modalType === 'Edit'">
                            <v-subheader style="font-size: 16px"> <v-icon color="secondary" class="mr-2"></v-icon>
                                Remaining Users</v-subheader>
                            <v-divider></v-divider>
                            <v-text-field placeholder="Search" prepend-inner-icon="search" v-model="searchRemaining"
                                outlined dense clearable> </v-text-field>
                            <v-list dense subheader style="max-height: 60vh; overflow-y: auto">
                                <v-list-item v-if="remainingUsers.length == 0">
                                    <v-list-item-content>
                                        <v-list-item-title style="color: grey">
                                            No remaining users
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-for="(user, index) in remainingUsers" :key="index">
                                    <v-list-item-action>
                                        <v-avatar :color="user.user.avatar ? 'white' : 'secondary'" size="36">
                                            <v-img v-if="user.user.avatar" referrerpolicy="no-referrer"
                                                :src="user.user.avatar" contain></v-img>
                                            <h2 v-else style="color: white; font-weight: normal">{{
                                                user.user.firstname.charAt(0) }}</h2>
                                        </v-avatar>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ user.user.firstname }} {{ user.user.surname }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ user.user.emailAddress }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <!-- <v-btn color="red" icon @click="removeUser(user, index)"><v-icon>block</v-icon></v-btn> -->
                                        <v-btn color="blue" v-if="!user.added"
                                            :loading="userLoading.includes(user.userId)" icon @click="addUser(user)">
                                            <v-icon>add</v-icon>
                                        </v-btn>
                                        <v-btn color="red" v-else icon @click="removeUser(user.data, index)">
                                            <v-icon>block</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
    components: {
        VuePdfEmbed,
    },
    data: () => ({
        orgId: null,
        roles: [],
        item: null,
        loading: false,
        selectedRows: [],
        teams: [],

        buffer: {},
        deletingTeam: false,
        dialog: false,
        headers: [{
            text: 'Action',
            align: 'center',
            sortable: false,
            value: 'action'
        },
        {
            text: 'Name',
            value: 'name',
            align: 'center',
        }, {
            text: 'Members',
            value: 'userTeams',
            align: 'center',
        },
        ],
        userHeaders: [{
            text: 'Action',
            align: 'center',
            sortable: false,
            value: 'action'
        },
        {
            text: 'Name',
            value: 'organisationUser.user.firstname',
            align: 'center',
        },
        {
            text: 'Surname',
            value: 'organisationUser.user.surname',
            align: 'center',
        }, {
            text: 'Position',
            value: 'organisationUser.position',
            align: 'center',
        },
        ],
        item: {},
        loading: false,
        loadingUsers: [],
        loadingUsersKey: 2000,
        orgUsers: [],
        savingTeam: false,
        search: '',
        searchUsers: null,
        selectedUser: {},
        searchRemaining: null,
        teams: [],
        userLoading: [],
    }),
    async created() {
        // await this.getOrgId();
        this.load();
    },
    mounted() {
        this.item = this.params;
        if (this.params.api) {
            console.log(this.params)
            this.params.api.addEventListener(
                "selectionChanged",
                this.rowSelectionChanged
            );
        }
        this.getTeams();
    },
    computed: {
        filterOrgUsers() {
            let result = this.orgUsers
            if (this.item && this.item.id) {
                result = result.filter(x => !this.item.userTeams.map(y => y.organisationUserId).includes(x.id))
            }
            return result
        },
        filterTeams() {
            let result = this.teams
            if (this.search) {
                result = result.filter(x => x.name.toLowerCase().includes(this.search.toLowerCase()))
            }
            return result
        },
        filteredUsers() {
            let result = this.item.userTeams ?? []
            if (this.searchUsers) {
                result = result.filter(x =>
                    (x.organisationUser.user.firstname.toLowerCase()).includes(this.searchUsers.toLowerCase()) ||
                    (x.organisationUser.user.surname.toLowerCase()).includes(this.searchUsers.toLowerCase())
                )
            }
            return result
        },
        remainingUsers() {
            let result = []
            if (this.item && this.item.userTeams) {
                result = [...new Set(this.item.userTeams.map(x => x.organisationUser.user.id))]
                result = this.orgUsers.filter(x => !result.includes(x.userId))
            }
            result.sort((a, b) => a.user.firstname.localeCompare(b.user.firstname))
            if (this.searchRemaining) {
                result = result.filter(x =>
                    (x.user.firstname.toLowerCase()).includes(this.searchRemaining.toLowerCase()) ||
                    (x.user.surname.toLowerCase()).includes(this.searchRemaining.toLowerCase()))
            }
            return result
        }
    },
    methods: {
        // CREATED
        async load() {
            this.orgId = this.params.orgId;
            this.getTeams();
            this.getRoles();
            this.getOrganisationUsers();
        },
        // GET
        async getOrganisationUsers() {
            this.orgUsers = await this.$API.getOrganisationUsersAdmin(this.orgId);
        },
        async getTeams() {
            this.loading = true;
            this.teams = await this.$API.getTeamsAdmin(this.orgId);
            this.loading = false;
        },
        async getRoles() {
            this.roles = await this.$API.getOrganisationRole(this.orgId)
        },
        //
        addTeam() {
            this.dialog = true;
            this.item = { modalType: 'Add', userTeams: [], organisationId: this.orgId };
        },
        closeDialog() {
            this.searchRemaining = null;
            this.searchUsers = null;
            this.item = {};
            this.params.updateTable();
            this.dialog = false;
        },
        async addUser(userItem) {
            this.userLoading.push(userItem.userId);
            let result = await this.$API.getUserPreviousTeams();
            let userTeam = result.find(element => element.teamId === this.item.id);
            if (userTeam) {
                console.log('Bring Back Entry Of Team User');
                let user = await this.$API.updateTeamUserAdmin({
                    id: userTeam.id,
                    isActive: true,
                    isDeleted: false
                });
                user.organisationUser = userItem;
                this.item.userTeams.push(user);
            }
            if (!userTeam) {
                console.log('Adding New Entry Of Team User');
                let user = await this.$API.createTeamUserAdmin({
                    teamId: this.item.id,
                    organisationUserId: userItem.id,
                });
                user.organisationUser = userItem;
                this.item.userTeams.push(user);
            }
            this.userLoading.splice(this.userLoading.indexOf(userItem.userId), 1);
            this.params.updateTable();
            this.$message.success('User Added to Team');
        },
        editTeam(item) {
            this.item = item;
            this.item.modalType = 'Edit';
            this.item.organisationId = this.orgId;
            this.dialog = true;
        },
        removeItem(item) {
            this.$confirm('Are you sure you want to delete this team?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                this.deletingTeam = true;
                await this.$API.updateTeamAdmin({
                    id: item.id,
                    isActive: false,
                    isDeleted: true
                });
                this.teams.splice(this.teams.findIndex(x => x.id == item.id), 1);
                this.closeDialog();
                this.deletingTeam = false;
                this.$message.success('Successfully deleted!');
            }).catch(() => {
                this.$message.error('Delete cancelled');
                this.deletingTeam = false;
            });
        },
        removeUserItem(user, index) {
            this.$confirm(`Are you sure you want to remove ${user.organisationUser.user.firstname} ${user.organisationUser.user.surname} from ${this.item.name}?`, 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                this.loadingUsers.push(index);
                this.loadingUsersKey++;
                await this.$API.updateTeamUserAdmin({
                    id: user.id,
                    isActive: false,
                    isDeleted: true
                });
                this.item.userTeams.splice(index, 1);
                this.loadingUsers.splice(this.loadingUsers.indexOf(index), 1);
                this.params.updateTable();
                this.$message.success('Successfully deleted!');
                this.loadingUsersKey++
            }).catch(() => {
                this.$message.error('Delete cancelled');
                this.loadingUsers.splice(this.loadingUsers.indexOf(index), 1);
                this.loadingUsersKey++;
            });
        },
        async saveTeam() {
            this.savingTeam = true;
            if (this.item.id) {
                await this.$API.updateTeamAdmin(this.item);
            } else {
                this.item.organisationId = this.orgId;
                let result = await this.$API.createTeamAdmin(this.item);
                let userTeams = this.item.userTeams.map(x => ({
                    teamId: result.id,
                    organisationUserId: x.organisationUser.id,
                }));
                await this.$API.createTeamUserAdmin({ batch: userTeams });
            }
            this.getTeams();
            this.closeDialog();
            this.savingTeam = false;
        },
        // AG GRID
        refresh() {
            if (this.params.refreshPanel) {
                this.load();
            }
        },
        resetTable() {
            this.params.resetTable();
        },
        rowSelectionChanged(event) {
            if (event.source != "gridInitializing") {
                let rows = this.params.api.getSelectedRows();
                this.selectedRows = rows;
            } else {
                this.params.api.deselectAll();
                this.selectedRows = [];
            }
        },
    },
};
</script>
